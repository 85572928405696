import { supportedConnectors } from 'connectkit';
import { detect } from 'detect-browser';
import redirects from './redirects';

const detectBrowser = () => detect()?.name ?? '';
const detectOS = () => detect()?.os ?? '';

const isIOS = () => detectOS().toLowerCase().includes('ios');
const isAndroid = () => detectOS().toLowerCase().includes('android');

const redirect = (uri: string, message?: string) => {
  const showMessage = false;

  if (showMessage) {
    return (
      <>
        {message} {uri}
      </>
    );
  } else {
    window.location.replace(uri);
    return <></>;
  }
};

const getConnector = (connectorId: string) => {
  return supportedConnectors.filter(
    (c) => c.id.toLowerCase() === connectorId.toLowerCase()
  )[0];
};
const getMobileAppUri = (connectorId: string) => {
  const c = getConnector(connectorId);
  if (isIOS()) {
    return c.appUrls?.ios ? c.appUrls.ios : '';
  } else if (isAndroid()) {
    return c.appUrls?.android ? c.appUrls.android : '';
  }
  return undefined;
};
const getBrowserAppUri = (connectorId: string) => {
  const c = getConnector(connectorId);
  const browser = detectBrowser();
  switch (browser) {
    case 'firefox':
      return c.extensions?.firefox ? c.extensions?.firefox : undefined;
    case 'safari':
      return c.extensions?.safari ? c.extensions?.safari : undefined;
    case 'chrome':
      return c.extensions?.chrome ? c.extensions?.chrome : undefined;
    default:
      return undefined;
  }
};
const getAppWebsiteUri = (connectorId: string) => {
  const c = getConnector(connectorId);
  return c.appUrls?.website ?? undefined;
};

const getWalletDownloadUri = (connectorId: string) => {
  let url: string | undefined =
    getMobileAppUri(connectorId) ??
    getBrowserAppUri(connectorId) ??
    getAppWebsiteUri(connectorId);
  return url;
};

// Version 0
const redirectV0 = (type: string) => {
  // Get pathname
  const path = window.location.pathname.split('/');
  const pathId = path[3];
  if (!pathId) return redirect('https://family.co', `pathId not found`);

  if (type === 'app') {
    // Check if any connectorId matches pathId
    const c = getConnector(pathId);
    if (!c) {
      return redirect(
        'https://family.co',
        `no connectorId found for connectorId: ${pathId}`
      );
    }

    // Get the download uri
    const downloadUri = getWalletDownloadUri(c.id);

    // Redirect to download uri if available
    if (!downloadUri) {
      return redirect('https://family.co', 'no uri');
    } else {
      return redirect(downloadUri, 'Redirecting...');
    }
  } else if (type === 'docs') {
    const goto: any = redirects.docs[pathId as keyof typeof redirects.docs];
    if (goto) return redirect(goto, 'Redirecting...');
    return redirect('https://docs.family.co');
  }

  // No route
  return redirect('https://family.co');
};

const App = () => {
  const path = window.location.pathname.split('/');

  // This may be easier to put into a directory with react-router, or move to nextjs
  if (path[2] === 'download') {
    // Temporary setup for versions, this setup will most likely change in the future
    const version = path[1];
    if (version === 'v0') return redirectV0('app');

    // No version available
    return redirect('https://family.co', 'version not supported');
  }

  // This may be easier to put into a directory with react-router, or move to nextjs
  if (path[2] === 'docs') {
    const version = path[1];
    if (version === 'v0') return redirectV0('docs');
    return redirect('https://docs.family.co');
  }

  // No route
  return redirect('https://family.co');
};

export default App;
